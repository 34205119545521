.button {
    z-index: 9999;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.button:hover {
    opacity: 0.5;
}

.button-text {
    font-size: calc(12px + 2vmin);
}