.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .alert {
    background: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black
  }
  
  .message {
    font-size: 16px;
    margin-bottom: 20px;
    color: black
  }
  
  .closeButton {
    background: #0056b3;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
  }
  
  .closeButton:hover {
    background: gray;
    /* color: #007aff; */
  }