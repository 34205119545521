.accordion-header h3 {
    margin: 0;
}

.accordion-header svg {
    font-size: 1.2rem;
    transition: transform 0.3s ease-in-out;
}

.accordion-header:hover svg {
    transform: rotate(180deg);
}